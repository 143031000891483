import React from "react"

import Layout from "../components/layout"
import TimelineGroup from "../components/cardGroup"
import SEO from "../components/seo"

const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Home" />
    <TimelineGroup />
  </Layout>
  )
}

export default IndexPage
