import React from "react"
import Image from "gatsby-image"
import { Card, CardBody, CardTitle, CardText } from "reactstrap"
import ReactAudioPlayer from 'react-audio-player'

import "./card.css"

const TimelineCard = (props) => (
    <Card>
        { props.featuredImage ? (
            <div className="featured-img">
                <Image fluid={props.featuredImage} alt={props.imageCaption} />
                <CardText className="text-center p-2 pl-3 pr-3">
                    <small className="text-muted font-italic">{props.imageCaption}</small>
                </CardText> 
            </div>
        ) : null }
        <CardBody>
        { props.postTitle ? (
            <CardTitle>{props.postTitle}, {props.postDate}</CardTitle>
        ) : '' }
        { !props.postTitle && props.postDate ? (
            <CardTitle>{props.postDate}</CardTitle>
        ) : '' }
            <CardText>{props.postContent}</CardText>
        { props.audioSrc ? (
            <div className="audio-section">
                <ReactAudioPlayer
                    src={props.audioSrc}
                    autoplay
                    controls
                    controlsList="nodownload"
                    />
                <p className="font-italic small w-75 m-auto">Listen to the interview excerpt of Alejandrina Fernan Del Rosario</p>
            </div>
            ) : ''}
        </CardBody>
    </Card>
)

export default TimelineCard