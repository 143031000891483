import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import TimelineCard from "./card"

const TimelineGroup = () => {
    const data = useStaticQuery(graphql `
        query {
          allMarkdownRemark(sort: {fields: frontmatter___date, order: ASC}) {
            group(field: frontmatter___category) {
              fieldValue
              edges {
                node {
                  frontmatter {
                    audioURL
                    category
                    date(formatString: "YYYY")
                    title
                    caption
                    featuredImg {
                      childImageSharp {
                        fluid (maxWidth: 400) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  rawMarkdownBody
                  id
                }
              }
            }
          }
        }        
    `)

  return (
      <>
          {data.allMarkdownRemark.group.map((group, i) => (
            <div className="timeline-section" key={i}>
                <ul className="timeline-list">
                    <li className="timeline-title">{group.fieldValue}</li>
                    {group.edges.map(({node}) => (
                        <li className="timeline-event" key={node.id}>
                          { !node.frontmatter.featuredImg && !node.frontmatter.audioURL ? 
                            <TimelineCard 
                              postTitle={node.frontmatter.title} 
                              postDate={node.frontmatter.date} 
                              postContent={node.rawMarkdownBody} /> 
                            : !node.frontmatter.audioURL ? 
                            <TimelineCard 
                              featuredImage={node.frontmatter.featuredImg.childImageSharp.fluid}
                              imageCaption={node.frontmatter.caption}
                              postTitle={node.frontmatter.title} 
                              postDate={node.frontmatter.date} 
                              postContent={node.rawMarkdownBody}/> 
                            : !node.frontmatter.featuredImg ?
                            <TimelineCard 
                                postTitle={node.frontmatter.title} 
                                postDate={node.frontmatter.date} 
                                postContent={node.rawMarkdownBody} 
                                audioSrc={node.frontmatter.audioURL}/>
                            : <TimelineCard 
                                featuredImage={node.frontmatter.featuredImg.childImageSharp.fluid}
                                imageCaption={node.frontmatter.caption}
                                postTitle={node.frontmatter.title} 
                                postDate={node.frontmatter.date} 
                                postContent={node.rawMarkdownBody} 
                                audioSrc={node.frontmatter.audioURL}/> 
                          } 
                        </li>
                    ))}
                </ul> 
            </div>
        ))}
      </>
    )}

export default TimelineGroup